import React, { useState, useEffect, useRef } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { useTranslation } from "react-i18next";
import { app, auth, db, analytics } from "../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";

const functions = getFunctions(app, "us-central1");
const createCheckoutSession = httpsCallable(
  functions,
  "api/stripe/createCheckoutSession",
);

const Store = () => {
  const { t } = useTranslation();
  const { dashboardData } = useDashboard();
  const [loadingStates, setLoadingStates] = useState({});
  const descriptionRefs = useRef([]);

  const navigate = useNavigate();

  // Log Page View on Component Mount
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_title: "Store",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const handleCheckout = async (priceId, productId) => {
    if (!priceId) {
      console.error(`Price ID is undefined for productId: ${productId}`);
      return;
    }

    setLoadingStates((prevState) => ({ ...prevState, [productId]: true }));

    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User is not authenticated");
      setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
      return;
    }

    try {
      const result = await createCheckoutSession({
        priceId,
      });

      const sessionId = result.data.sessionId;

      const checkoutSessionRef = doc(
        db,
        "stripe_customers",
        userId,
        "checkout_sessions",
        sessionId,
      );

      const unsubscribe = onSnapshot(checkoutSessionRef, (snap) => {
        const data = snap.data();
        if (!data) {
          console.error("Snapshot data is undefined");
          setLoadingStates((prevState) => ({
            ...prevState,
            [productId]: false,
          }));
          return;
        }

        const { error, url } = data;
        if (error) {
          console.error(`An error occurred: ${error.message}`);
          setLoadingStates((prevState) => ({
            ...prevState,
            [productId]: false,
          }));
          unsubscribe();
        }
        if (url) {
          unsubscribe();
          window.location.href = url;
        }
      });

      // Log Checkout Initiation
      logEvent(analytics, "checkout_initiated", {
        product_id: productId,
        price_id: priceId,
        user_id: userId,
        timestamp: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
    }
  };

  const handleUpgradeNowClick = () => {
    // Log Upgrade Now Click
    logEvent(analytics, "upgrade_now_click", {
      action: "upgrade_now",
      timestamp: new Date().toISOString(),
    });

    navigate("/dashboard/store");
  };

  const formatValue = (key, value) => {
    if (key === "planLimitations.fills" && value === 0) {
      return t(`${key}`, { value: "Unlimited" });
    }
    if (typeof value === "boolean") {
      return value
        ? t(`${key}`, { value: "Yes" })
        : t(`${key}`, { value: "No" });
    }
    return t(`${key}`, { value });
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "eur":
        return "€";
      case "usd":
        return "$";
      default:
        return currency.toUpperCase();
    }
  };

  if (!dashboardData) {
    return <div>{t("store.loading")}</div>;
  }

  const { plans, user } = dashboardData;

  const getPlanName = (priceId) => {
    const plan = plans.find((plan) =>
      plan?.productData?.prices.some((price) => price.id === priceId),
    );
    return plan ? plan.name : "Unknown Plan";
  };

  const isExpired = (expirationDate) => {
    const expirationTime = new Date(expirationDate.seconds * 1000).getTime();
    const currentTime = new Date().getTime();
    return expirationTime > currentTime;
  };

  const renderPlans = (name, mostPopular) => {
    return plans
      .filter((plan) => plan.name === name)
      .map((plan, index) => {
        const productData = plan.productData;

        // Determine the price
        let price;
        let priceId = null;

        if (name === "Free") {
          price = `${getCurrencySymbol("eur")} 0`;
        } else if (
          name !== "Enterprise" &&
          productData &&
          productData.prices.length > 0
        ) {
          price = `${getCurrencySymbol(productData.prices[0].currency)} ${
            productData.prices[0].unit_amount / 100
          }`;
          priceId = productData.prices[0].id;
        } else {
          price = null; // No price for Enterprise
        }

        const isLoading = loadingStates[plan.productId];

        return (
          <div key={plan.id} className={`plan ${plan.name.toLowerCase()}`}>
            {mostPopular && <div className="green-label">Most Popular</div>}
            <div className="plan-content">
              {/* Grouping the icon and name within a single div */}
              <div className="plan-header">
                <span
                  className={`plan-icon plan-icon-${name.toLowerCase()}`}
                ></span>
                <h3 className="name">{plan.name}</h3>
              </div>
              <p
                className="description"
                ref={(el) => (descriptionRefs.current[index] = el)}
              >
                {t(`store.${plan.description}`)}
              </p>
              {name === "Enterprise" && (
                <p className="enterprise-desc">
                  {t("store.enterpriseDescription")}
                </p>
              )}
              {/* Conditionally render the price only if it exists */}
              {price && (
                <span className="price">
                  {price} <span className="label">/ month</span>
                </span>
              )}
              {plan.limitations && Object.keys(plan.limitations).length > 0 && (
                <ul className="features">
                  {Object.keys(plan.limitations)
                    .sort()
                    .map(
                      (key) =>
                        key !== "fillsLimit" &&
                        key !== "numberOfUsers" &&
                        key !== "dynamicWizard" && (
                          <li key={key}>
                            <span className="icon">check_circle</span>
                            <p>
                              {formatValue(
                                `planLimitations.${key}`,
                                plan.limitations[key],
                              )}
                            </p>
                          </li>
                        ),
                    )}
                </ul>
              )}
            </div>
            <div className="plan-button">
              {name === "Enterprise" ? (
                <a
                  href="mailto:support@yourcompany.com"
                  className="secondary-button"
                  onClick={() =>
                    logEvent(analytics, "contact_us_click", {
                      action: "contact_us",
                      timestamp: new Date().toISOString(),
                    })
                  }
                >
                  {t("store.contactUs")}
                </a>
              ) : name === "Free" ? null : ( // No button for Free plan
                <button
                  className={`${
                    name === "Pro" ? "primary-button" : "secondary-button"
                  } ${isLoading ? "loading" : ""}`}
                  onClick={() => handleCheckout(priceId, plan.productId)}
                  disabled={isLoading}
                >
                  {isLoading
                    ? t("store.loading")
                    : !user.trialPlanUsed && plan.hasTrial
                      ? t("store.startTrial")
                      : t("store.subscribe")}
                </button>
              )}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="full-width-layout">
      <div className="title-section">
        <h2>{t("store.title")}</h2>
        <p>{t("store.availablePlans")}</p>
      </div>
      <div className="store-cards">
        {renderPlans("Free")}
        {renderPlans("Pro", true)}
        {renderPlans("Enterprise")}
      </div>
    </div>
  );
};

export default Store;
