// src/components/Auth/ResetPassword.jsx

import React, { useState, useEffect } from "react";
import { auth, analytics } from "../../firebase"; // Import analytics
import { sendPasswordResetEmail } from "firebase/auth";
import { logEvent } from "firebase/analytics";
import logo from "../../assets/images/fillyform-logo.svg";
import { Link } from "react-router-dom";
import { useNotification } from "../../context/NotificationContext";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addNotification } = useNotification();
  // const navigate = useNavigate();

  // Define your strong email regex
  const strongEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

  const validateField = (name, value) => {
    let error = "";
    if (name === "email") {
      if (!value) {
        error = "Email is required.";
      } else if (!strongEmailRegex.test(value)) {
        error = "Please enter a valid email address.";
      }
    }
    return error;
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    const emailError = validateField("email", formData.email);

    if (emailError) {
      formIsValid = false;
      newErrors["email"] = emailError;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Optionally validate fields on change
    const fieldError = validateField(name, value);
    setErrors({ ...errors, [name]: fieldError });
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case "auth/invalid-email":
        return "The email address is not valid.";
      case "auth/user-not-found":
        return "No user found with this email address.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  /**
   * Handles logging of successful password reset email events.
   * @param {number} duration - The duration of the password reset process in milliseconds.
   */
  const handleResetSuccess = (duration) => {
    logEvent(analytics, "password_reset_email_sent", { duration });

    addNotification({
      title: "Success!",
      message: `A password reset email has been sent to ${formData.email}. Please check your inbox.`,
      icon: "check_circle",
      type: "success",
    });

    // Optional: Redirect to login or another page
    // navigate("/login");
  };

  /**
   * Handles logging of failed password reset attempts.
   * @param {string} errorCode - The error code returned by Firebase.
   */
  const handleResetFailure = (errorCode) => {
    logEvent(analytics, "password_reset_failure", { error: errorCode });

    setErrors({ form: getErrorMessage(errorCode) });
    addNotification({
      title: "Error",
      message: getErrorMessage(errorCode),
      icon: "error",
      type: "error",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    const resetStartTime = Date.now(); // Start timing reset process
    try {
      await sendPasswordResetEmail(auth, formData.email);
      const resetDuration = Date.now() - resetStartTime;
      handleResetSuccess(resetDuration);
    } catch (error) {
      handleResetFailure(error.code);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Log page view event manually
  useEffect(() => {
    if (!analytics) return; // Ensure analytics is initialized

    const pagePath = "/reset-password";
    const pageTitle = "Reset Password - FillyForm";

    document.title = pageTitle; // Set the document title

    logEvent(analytics, "page_view", {
      page_location: window.location.href,
      page_path: pagePath,
      page_title: pageTitle,
    });
  }, [analytics]);

  return (
    <div className="auth-container">
      <img src={logo} alt="FillyForm" className="logo" />
      <form onSubmit={handleSubmit}>
        <div className="form-header">
          <h1>Recover your password</h1>
          <p>
            Please enter the email address you’d like your password reset
            information sent to.
          </p>
        </div>

        <fieldset>
          <div className="form-field">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address"
              className={errors.email ? "has-error" : ""}
              maxLength="254"
              required
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
        </fieldset>
        {errors.form && <div className="error">{errors.form}</div>}
        <button
          type="submit"
          className={`primary-button ${isSubmitting ? "loading" : ""}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Recover password"}
        </button>
        <Link to="/login" className="link">
          Back to login
        </Link>
      </form>
      <p className="copyrights">© {new Date().getFullYear()} FillyForm</p>
    </div>
  );
};

export default ResetPassword;
