// src/pages/dashboard/insights/GeneratedContent.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { db, analytics } from "../../../firebase"; // Import analytics instance
import { logEvent } from "firebase/analytics"; // Import logEvent

const GeneratedContent = ({
  dashboardData,
  selectedFieldId,
  setSelectedFieldId,
  generatedData,
  setGeneratedData,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  handlePageChange,
  publishedFields,
  handleFieldChange,
}) => {
  const { fieldId, page } = useParams();
  const navigate = useNavigate();
  const totalPages = Math.ceil(generatedData.length / entriesPerPage);
  const [loading, setLoading] = useState(true);

  // Log page view when component mounts
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: "Generated Content",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Runs once on mount

  useEffect(() => {
    const pageNum = parseInt(page, 10);
    if (isNaN(pageNum) || pageNum < 1 || pageNum > totalPages) {
      navigate(`/dashboard/insights/generated/${fieldId}/1`);
    } else {
      setCurrentPage(pageNum);
    }

    // Log pagination event if page number is valid and differs from currentPage
    if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
      if (analytics) {
        logEvent(analytics, "pagination_view", {
          page_number: pageNum,
          total_pages: totalPages,
        });
      }
    }
  }, [page, fieldId, navigate, totalPages, setCurrentPage, analytics]);

  useEffect(() => {
    // Update the selected fieldId based on URL
    if (fieldId !== selectedFieldId) {
      setSelectedFieldId(fieldId);

      // Log field selection
      if (analytics) {
        logEvent(analytics, "field_selection", {
          field_id: fieldId,
        });
      }
    }
  }, [fieldId, selectedFieldId, setSelectedFieldId, analytics]);

  useEffect(() => {
    let unsubscribeGeneratedData;

    if (dashboardData && selectedFieldId) {
      unsubscribeGeneratedData = subscribeToGeneratedData();
    } else {
      setLoading(false);
    }

    return () => {
      if (unsubscribeGeneratedData) unsubscribeGeneratedData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData, selectedFieldId]);

  const subscribeToGeneratedData = () => {
    if (dashboardData) {
      const companyId = dashboardData.user.companyId;
      if (!companyId) {
        console.error("Company ID is missing in dashboardData");
        setLoading(false);
        return;
      }

      const generatedDataRef = collection(
        db,
        "insights",
        companyId,
        "fields",
        selectedFieldId,
        "generated_data",
      );

      return onSnapshot(
        generatedDataRef,
        (snapshot) => {
          const data = [];
          snapshot.forEach((genDataDoc) =>
            data.push({ id: genDataDoc.id, ...genDataDoc.data() }),
          );

          data.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
          setGeneratedData(data);
          setLoading(false);

          // Optionally, log data fetch success
          if (analytics) {
            logEvent(analytics, "generated_data_fetch_success", {
              field_id: selectedFieldId,
              number_of_entries: data.length,
            });
          }
        },
        (error) => {
          console.error("Error fetching generated data:", error);
          setLoading(false);

          // Optionally, log data fetch failure
          if (analytics) {
            logEvent(analytics, "generated_data_fetch_failure", {
              field_id: selectedFieldId,
              error: error.message,
            });
          }
        },
      );
    } else {
      setLoading(false);
    }
  };

  // Function to format date as "20 January 2021"
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  if (loading) {
    return (
      <div className="section-loader" role="status" aria-live="polite">
        <div className="loader"></div>
        <p>Preparing your data ...</p>
      </div>
    );
  }

  // If no fields are defined
  if (publishedFields.length === 0) {
    return (
      <div className="no-data-wrapper">
        <span className="icon">settings</span>
        <h2>No fields defined yet</h2>
        <p>Add a new field to get started.</p>
        <button
          className="primary-button"
          onClick={() => navigate("/dashboard/fields/add")}
        >
          Add new
        </button>
      </div>
    );
  }

  // If we have published fields but no generated data
  // Show field selector and "no data" message
  if (generatedData.length === 0) {
    return (
      <div>
        <div className="filters-row">
          <div className="form-field">
            <label htmlFor="field-select">Field</label>
            <select
              id="field-select"
              value={selectedFieldId}
              onChange={handleFieldChange}
            >
              {publishedFields.map((field) => (
                <option key={field.id} value={field.id}>
                  {field.name}
                </option>
              ))}
            </select>
          </div>

          {publishedFields.length > 1 && (
            <div className="form-field">
              <label htmlFor="entries-per-page-select">Entries per page:</label>
              <select
                id="entries-per-page-select"
                value={entriesPerPage}
                onChange={(e) => {
                  const newEntries = parseInt(e.target.value);
                  setEntriesPerPage(newEntries);
                  setCurrentPage(1); // Reset to first page on change

                  // Log entries per page change
                  if (analytics) {
                    logEvent(analytics, "entries_per_page_change", {
                      entries_per_page: newEntries,
                    });
                  }
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          )}
        </div>

        <div className="no-data-wrapper">
          <span className="icon">list</span>
          <h2>No generated content yet</h2>
          <p>Once data is available, it will appear here.</p>
        </div>
      </div>
    );
  }

  // We have data
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = generatedData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );

  return (
    <div>
      <div className="filters-row">
        <div className="form-field">
          <label htmlFor="field-select">Field</label>
          <select
            id="field-select"
            value={selectedFieldId}
            onChange={handleFieldChange}
          >
            {publishedFields.map((field) => (
              <option key={field.id} value={field.id}>
                {field.name}
              </option>
            ))}
          </select>
        </div>

        {publishedFields.length > 1 && (
          <div className="form-field">
            <label htmlFor="entries-per-page-select">Entries per page:</label>
            <select
              id="entries-per-page-select"
              value={entriesPerPage}
              onChange={(e) => {
                const newEntries = parseInt(e.target.value);
                setEntriesPerPage(newEntries);
                setCurrentPage(1); // Reset to first page on change

                // Log entries per page change
                if (analytics) {
                  logEvent(analytics, "entries_per_page_change", {
                    entries_per_page: newEntries,
                  });
                }
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        )}
      </div>

      <ul className="generated-content">
        {currentEntries.map((genData) => (
          <li key={genData.id}>
            <div className="date-time">
              <div className="line">
                <span className="icon">calendar_month</span>
                {formatDate(genData.createdAt.seconds)}
              </div>
              <div className="line">
                <span className="icon">schedule</span>
                {new Date(
                  genData.createdAt.seconds * 1000,
                ).toLocaleTimeString()}
              </div>
            </div>
            <ul>
              {genData.prompt &&
                genData.prompt.map((item, index) => (
                  <li key={index}>
                    <span>
                      <span className="secondary-text">{item.question}</span>
                      <br />
                      <b className="primary-text">{item.answer}</b>
                    </span>
                  </li>
                ))}
            </ul>
            <p>
              <span>{genData.message}</span>
            </p>
          </li>
        ))}
      </ul>
      <div className="pagination">
        <button
          onClick={() => {
            handlePageChange("prev");

            // Log pagination click event
            if (analytics) {
              logEvent(analytics, "pagination_click", {
                action: "prev",
                current_page: currentPage,
                total_pages: totalPages,
              });
            }
          }}
          disabled={currentPage === 1}
          className="secondary-button"
        >
          <span className="icon">arrow_back</span>
        </button>
        <span>
          {" "}
          Page {currentPage} of {totalPages}{" "}
        </span>
        <button
          onClick={() => {
            handlePageChange("next");

            // Log pagination click event
            if (analytics) {
              logEvent(analytics, "pagination_click", {
                action: "next",
                current_page: currentPage,
                total_pages: totalPages,
              });
            }
          }}
          disabled={currentPage === totalPages}
          className="secondary-button"
        >
          <span className="icon">arrow_forward</span>
        </button>
      </div>
    </div>
  );
};

export default GeneratedContent;
