import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db, analytics } from "../../../firebase";
import { logEvent } from "firebase/analytics";
import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  addDoc,
  getDoc,
} from "firebase/firestore";
import DOMPurify from "dompurify";
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";
import PreviewModal from "./PreviewModal";

const FieldForm = () => {
  const { fieldId } = useParams();
  const { dashboardData } = useDashboard();
  const { addNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();

  // ---------- STATE ----------
  const [field, setField] = useState({
    name: "",
    cssSelector: "",
    charLimit: "",
    page: "",
    language: "en",
    fieldDescription: "",
    storeData: false,
    published: false,
    questions: [],
    active: true,
  });

  // Track expansions for each question: expandedSteps[i] = boolean
  const [expandedSteps, setExpandedSteps] = useState([]);
  const [isFieldLoaded, setIsFieldLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState(null);

  // Example plan constraints from your context
  const planLimitations = dashboardData?.activePlan?.limitations || {};
  const maxSteps = planLimitations?.steps || 1;
  const canAddConditionals = planLimitations?.conditionals || false;
  const maxOptions = 20;

  // Subset of languages for brevity
  const europeanLanguages = [
    { name: "English", code: "en" },
    { name: "French", code: "fr" },
    { name: "German", code: "de" },
    { name: "Spanish", code: "es" },
    { name: "Welsh", code: "cy" },
  ];

  // ---------- 1) SNAPSHOT to load data in real time (if desired) ----------
  useEffect(() => {
    const companyId = dashboardData.user.companyId;
    const fieldsRef = collection(db, "companies", companyId, "fields");

    const unsubscribe = onSnapshot(fieldsRef, (snapshot) => {
      const updatedFields = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dashboardData.company.fields = updatedFields;

      // If we are editing an existing field
      if (fieldId) {
        const existingField = updatedFields.find((f) => f.id === fieldId);
        if (existingField) {
          // IMPORTANT: we do NOT re-init expansions here
          // We just update the field content
          setField(existingField);
          // log page_view_edit once if not yet logged
        }
      }
      // If fieldId is NOT set => new doc => we might log page_view_add_new
      setIsFieldLoaded(true);
    });

    return () => unsubscribe();
  }, [fieldId, dashboardData.user.companyId, dashboardData.company]);

  // 2) ONCE the doc is loaded, decide which page_view event to log.
  //    We'll store a ref so we only do it once.
  const pageViewLogged = useRef(false);
  useEffect(() => {
    if (!isFieldLoaded || pageViewLogged.current) return;

    if (fieldId) {
      // If there's already data in `field.questions` => it's an edit
      logEvent(analytics, "page_view_edit", { fieldId });
    } else {
      // brand new
      logEvent(analytics, "page_view_add_new");
    }
    pageViewLogged.current = true;
  }, [isFieldLoaded, fieldId]);

  // 3) If user is on /preview but we have no questions => revert, else open modal
  useEffect(() => {
    if (!isFieldLoaded) return;

    if (location.pathname.endsWith("/preview")) {
      if (field.questions.length === 0) {
        navigate(location.pathname.replace("/preview", ""), { replace: true });
      } else {
        setIsModalOpen(true);
      }
    } else {
      setIsModalOpen(false);
    }
  }, [location.pathname, field.questions, isFieldLoaded, navigate]);

  // ---------- 4) OnChange handlers (no validation here) ----------
  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    const cleanValue = DOMPurify.sanitize(
      type === "checkbox" ? checked : value,
    );

    if (name === "charLimit") {
      setField((prev) => ({
        ...prev,
        charLimit: cleanValue.replace(/\D+/g, ""),
      }));
    } else {
      setField((prev) => ({
        ...prev,
        [name]: cleanValue,
      }));
    }
  };

  const handleQuestionChange = (qIndex, qKey, rawValue) => {
    const safeVal = DOMPurify.sanitize(rawValue);
    setField((prev) => {
      const newQs = [...prev.questions];
      newQs[qIndex][qKey] = safeVal;
      return { ...prev, questions: newQs };
    });
  };

  const handleOptionChange = (qIdx, optIdx, rawVal) => {
    const safeVal = DOMPurify.sanitize(rawVal);
    setField((prev) => {
      const newQs = [...prev.questions];
      newQs[qIdx].options[optIdx] = safeVal;
      return { ...prev, questions: newQs };
    });
  };

  // Toggling hasConditional
  const handleHasConditionalToggle = (qIndex, checked) => {
    logEvent(analytics, "fieldform_conditional_toggle", {
      questionIndex: qIndex,
      toggledTo: checked,
    });
    setField((prev) => {
      const clone = [...prev.questions];
      if (!clone[qIndex].hasConditional && checked) {
        clone[qIndex].conditions = {
          slideNo: "1",
          condition: "equals",
          value: "",
        };
      }
      clone[qIndex].hasConditional = checked;
      return { ...prev, questions: clone };
    });
  };

  const handleConditionChange = (qIdx, condKey, rawVal) => {
    const safeVal = DOMPurify.sanitize(rawVal);
    setField((prev) => {
      const newQs = [...prev.questions];
      newQs[qIdx].conditions = {
        ...newQs[qIdx].conditions,
        [condKey]: safeVal,
      };
      return { ...prev, questions: newQs };
    });
  };

  // ---------- 5) Add/Remove slides, preserving expansions ----------
  // By default, a newly added slide is open => push `true`
  const addQuestion = () => {
    logEvent(analytics, "fieldform_add_slide", {
      currentSlideCount: field.questions.length,
    });

    const blankQ = {
      quote: "",
      type: "text",
      placeholder: "",
      options: [],
      hasConditional: false,
      conditions: { slideNo: "", condition: "equals", value: "" },
    };

    setField((prev) => ({
      ...prev,
      questions: [...prev.questions, blankQ],
    }));

    setExpandedSteps((prev) => [...prev, true]); // keep old expansions, add new = open
  };

  // remove a question
  const confirmDeleteQuestion = (qIdx) => {
    removeQuestion(qIdx);
    setDeleteConfirmationIndex(null);
  };
  const cancelDeleteQuestion = () => setDeleteConfirmationIndex(null);

  const removeQuestion = (index) => {
    logEvent(analytics, "fieldform_remove_slide", { removeIndex: index });

    setField((prev) => {
      const newQs = [...prev.questions];
      newQs.splice(index, 1);
      return { ...prev, questions: newQs };
    });

    setExpandedSteps((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  // add an option
  const addOption = (qIdx) => {
    setField((prev) => {
      const newQs = [...prev.questions];
      if (!Array.isArray(newQs[qIdx].options)) {
        newQs[qIdx].options = [];
      }
      if (newQs[qIdx].options.length >= maxOptions) {
        addNotification({
          title: "Error",
          message: `You can only add up to ${maxOptions} options.`,
          icon: "error",
          type: "error",
        });
      } else {
        newQs[qIdx].options.push("");
      }
      return { ...prev, questions: newQs };
    });
  };

  // remove an option
  const removeOption = (qIdx, optIdx) => {
    setField((prev) => {
      const newQs = [...prev.questions];
      newQs[qIdx].options.splice(optIdx, 1);
      return { ...prev, questions: newQs };
    });
  };

  // Expand/collapse a question
  const toggleStep = (idx) => {
    setExpandedSteps((prev) => {
      const copy = [...prev];
      // flip only the clicked index
      copy[idx] = !copy[idx];
      return copy;
    });
  };

  // ---------- 6) Validation + Save ----------
  const validateFieldsOnSave = (draft) => {
    const newErrors = {};

    // Field-level
    if (!draft.name.trim()) {
      newErrors.name = "Field name is required.";
    } else if (draft.name.trim().length > 100) {
      newErrors.name = "Field name cannot exceed 100 characters.";
    }

    if (!draft.cssSelector.trim()) {
      newErrors.cssSelector = "CSS selector is required.";
    }

    if (!draft.charLimit) {
      newErrors.charLimit = "Maximum length is required.";
    } else {
      const limitInt = parseInt(draft.charLimit.trim(), 10);
      if (!Number.isFinite(limitInt) || limitInt <= 0) {
        newErrors.charLimit = "Maximum length must be a positive number.";
      }
    }

    if (!draft.language.trim()) {
      newErrors.language = "Field language is required.";
    }

    // question-level
    draft.questions.forEach((q, i) => {
      const baseKey = `question${i}`;
      if (!q.quote.trim()) {
        newErrors[baseKey] = "Question name is required.";
      } else if (q.quote.trim().length > 200) {
        newErrors[baseKey] = "Question name cannot exceed 200 characters.";
      }

      if (
        (q.type === "singleSelect" || q.type === "multiSelect") &&
        !q.options.length
      ) {
        newErrors[`${baseKey}options`] =
          "Options are required for select type questions.";
      }

      if (q.hasConditional) {
        const slideNoInt = parseInt((q.conditions.slideNo || "").trim(), 10);
        if (!Number.isFinite(slideNoInt) || slideNoInt <= 0) {
          newErrors[`${baseKey}condition`] =
            "Slide no must be a positive number.";
        }
        if (!q.conditions.value.trim()) {
          newErrors[`${baseKey}value`] = "Condition value is required.";
        }
      }
    });

    return newErrors;
  };

  const saveField = async () => {
    setShowErrors(true);

    const finalErrors = validateFieldsOnSave(field);
    if (Object.keys(finalErrors).length > 0) {
      setErrors(finalErrors);
      addNotification({
        title: "Error",
        message: "Please fix the errors before saving.",
        icon: "error",
        type: "error",
      });
      return;
    }

    setErrors({});
    setIsSaving(true);

    logEvent(analytics, "fieldform_save", {
      fieldId: fieldId || "new",
      questionCount: field.questions.length,
    });

    try {
      const companyId = dashboardData.user.companyId;
      const companyRef = doc(db, "companies", companyId);
      const fieldsRef = collection(companyRef, "fields");

      if (fieldId) {
        // Merge with existing doc
        const fieldDocRef = doc(fieldsRef, fieldId);
        const snap = await getDoc(fieldDocRef);
        if (snap.exists()) {
          await setDoc(
            fieldDocRef,
            { ...snap.data(), ...field },
            { merge: true },
          );
        } else {
          await setDoc(fieldDocRef, field, { merge: true });
        }
      } else {
        // brand new doc
        const newFieldRef = await addDoc(fieldsRef, field);
        const newId = newFieldRef.id;
        navigate(`/dashboard/fields/edit/${newId}`);
      }

      addNotification({
        title: "Success!",
        message: "Field saved successfully.",
        icon: "check_circle",
        type: "success",
      });
    } catch (err) {
      console.error("Error saving field:", err);
      addNotification({
        title: "Error",
        message: `Error saving field: ${err.message}`,
        icon: "error",
        type: "error",
      });
    } finally {
      setIsSaving(false);
    }
  };

  // ---------- 7) Preview ----------
  const openPreviewModal = () => {
    logEvent(analytics, "fieldform_open_preview", {
      questionCount: field.questions.length,
    });
    navigate(`${location.pathname}/preview`);
  };

  const closePreviewModal = () => {
    navigate(location.pathname.replace("/preview", ""));
  };

  const isPreviewButtonEnabled = () => {
    return field.questions.some((q) => {
      return (
        q.quote &&
        q.type &&
        (q.type === "text" ||
          (q.type === "singleSelect" && q.options.length > 0) ||
          (q.type === "multiSelect" && q.options.length > 0))
      );
    });
  };

  // ---------- 8) Render ----------
  if (!isFieldLoaded) {
    return (
      <div className="center-layout fields-editor">
        <div className="loading-screen">
          <div className="spinner" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="center-layout fields-editor">
      <div className="description">
        <div className="title-section">
          <h2>{fieldId ? "Edit field" : "Add your new field"}</h2>
          <p>
            Configure your new form field, where FillyForm will prompt users to
            help fill in the required text
          </p>
        </div>
      </div>
      <div className="form">
        {/* Field settings */}
        <div className="step step-settings">
          <div className="step-header">
            <h3>Field settings</h3>
          </div>
          <div className="step-content">
            {/* Field name */}
            <div
              className={`form-field ${showErrors && errors.name ? "has-error" : ""}`}
            >
              <label>Field name - used internally</label>
              <input
                type="text"
                name="name"
                value={field.name}
                onChange={handleFieldChange}
                maxLength={100}
                placeholder="Job application - personal description"
              />
              {showErrors && errors.name && (
                <div className="error">{errors.name}</div>
              )}
            </div>

            {/* CSS selector */}
            <div
              className={`form-field ${showErrors && errors.cssSelector ? "has-error" : ""}`}
            >
              <label>
                Field CSS selector{" "}
                <a
                  href="google.com"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  See guide
                </a>
              </label>
              <input
                type="text"
                name="cssSelector"
                value={field.cssSelector}
                onChange={handleFieldChange}
                placeholder="input[type=email].hs-input"
              />
              {showErrors && errors.cssSelector && (
                <div className="error">{errors.cssSelector}</div>
              )}
            </div>

            {/* charLimit */}
            <div
              className={`form-field ${showErrors && errors.charLimit ? "has-error" : ""}`}
            >
              <label>Maximum length - maximum chars accepted</label>
              <input
                type="number"
                name="charLimit"
                value={field.charLimit}
                onChange={handleFieldChange}
                placeholder="250"
              />
              {showErrors && errors.charLimit && (
                <div className="error">{errors.charLimit}</div>
              )}
            </div>

            {/* Field description */}
            <div
              className={`form-field ${
                showErrors && errors.fieldDescription ? "has-error" : ""
              }`}
            >
              <label>Field description</label>
              <textarea
                name="fieldDescription"
                value={field.fieldDescription}
                onChange={handleFieldChange}
                placeholder="What's this field about?"
              />
              {showErrors && errors.fieldDescription && (
                <div className="error">{errors.fieldDescription}</div>
              )}
            </div>

            {/* language */}
            <div
              className={`form-field ${showErrors && errors.language ? "has-error" : ""}`}
            >
              <label>Field Language</label>
              <select
                name="language"
                value={field.language}
                onChange={handleFieldChange}
              >
                {europeanLanguages.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
              {showErrors && errors.language && (
                <div className="error">{errors.language}</div>
              )}
            </div>

            {/* storeData */}
            <div className="form-field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="storeData"
                  checked={!!field.storeData}
                  onChange={handleFieldChange}
                  className="visually-hidden custom-checkbox"
                />
                <span className="checkbox-indicator" /> Store generated data
              </label>
            </div>

            {/* published */}
            <div className="form-field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="published"
                  checked={!!field.published}
                  onChange={handleFieldChange}
                  className="visually-hidden custom-checkbox"
                />
                <span className="checkbox-indicator" /> Published
              </label>
            </div>
          </div>
        </div>

        {/* Question slides */}
        {field.questions.map((question, idx) => {
          // Is this question currently expanded or collapsed?
          const isOpen = expandedSteps[idx] === true;
          const baseKey = `question${idx}`;

          return (
            <div key={idx} className={`step ${isOpen ? "opened" : ""}`}>
              <div className="step-header">
                <h3>
                  <div className="header-text">
                    Slide {idx + 1}
                    <span>{question.quote}</span>
                  </div>
                  <div className="step-actions">
                    <button
                      type="button"
                      className={`expand-collapse-button icon-button icon ${
                        isOpen ? "opened" : ""
                      }`}
                      onClick={() => toggleStep(idx)}
                    >
                      add
                    </button>
                    <button
                      type="button"
                      className="delete-button icon icon-button"
                      onClick={() => setDeleteConfirmationIndex(idx)}
                    >
                      delete
                    </button>
                  </div>
                </h3>
              </div>

              {/* Confirm deletion? */}
              {deleteConfirmationIndex === idx && (
                <div className="delete-confirmation">
                  <p>Are you sure you want to delete this slide?</p>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={cancelDeleteQuestion}
                      className="secondary-button"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      onClick={() => confirmDeleteQuestion(idx)}
                      className="red-button"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}

              {/* If expanded => show content */}
              {isOpen && (
                <div className="step-content">
                  {/* question name */}
                  <div
                    className={`form-field ${
                      showErrors && errors[baseKey] ? "has-error" : ""
                    }`}
                  >
                    <label>Question name (type your question)</label>
                    <input
                      type="text"
                      value={question.quote}
                      onChange={(e) =>
                        handleQuestionChange(idx, "quote", e.target.value)
                      }
                      placeholder="What’s the name of your product?"
                      maxLength={200}
                    />
                    {showErrors && errors[baseKey] && (
                      <div className="error">{errors[baseKey]}</div>
                    )}
                  </div>

                  {/* type */}
                  <div className="form-field">
                    <label>Answer type</label>
                    <select
                      value={question.type}
                      onChange={(e) =>
                        handleQuestionChange(idx, "type", e.target.value)
                      }
                    >
                      <option value="text">Text</option>
                      <option value="singleSelect">Single select</option>
                      <option value="multiSelect">Multi select</option>
                    </select>
                  </div>

                  {/* placeholder */}
                  <div className="form-field">
                    <label>Placeholder (optional)</label>
                    <input
                      type="text"
                      value={question.placeholder || ""}
                      onChange={(e) =>
                        handleQuestionChange(idx, "placeholder", e.target.value)
                      }
                      placeholder="Placeholder"
                    />
                  </div>

                  {/* if single/multi => options */}
                  {(question.type === "singleSelect" ||
                    question.type === "multiSelect") && (
                    <div
                      className={`form-field options-wrapper ${
                        showErrors && errors[`${baseKey}options`]
                          ? "has-error"
                          : ""
                      }`}
                    >
                      <label>Add options</label>
                      {question.options.map((opt, optIdx) => (
                        <div
                          key={optIdx}
                          className="option-field field-actions-wrapper"
                        >
                          <input
                            type="text"
                            value={opt}
                            onChange={(e) =>
                              handleOptionChange(idx, optIdx, e.target.value)
                            }
                            placeholder={`Option ${optIdx + 1}`}
                            maxLength={100}
                          />
                          <span
                            className="icon accent-link"
                            onClick={() => removeOption(idx, optIdx)}
                          >
                            delete
                          </span>
                        </div>
                      ))}
                      {showErrors && errors[`${baseKey}options`] && (
                        <div className="error">
                          {errors[`${baseKey}options`]}
                        </div>
                      )}
                      {question.options.length < maxOptions && (
                        <span
                          className="accent-link"
                          onClick={() => addOption(idx)}
                        >
                          Add new +
                        </span>
                      )}
                    </div>
                  )}

                  {/* Conditionals */}
                  {canAddConditionals && idx > 0 && (
                    <>
                      <div className="form-field-checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={!!question.hasConditional}
                            onChange={(e) =>
                              handleHasConditionalToggle(idx, e.target.checked)
                            }
                            className="visually-hidden custom-checkbox"
                          />
                          <span className="checkbox-indicator" /> Add display
                          condition
                        </label>
                      </div>

                      {question.hasConditional && (
                        <div className="form-field conditions">
                          <div className="slide-no">
                            <label>Slide no</label>
                            <select
                              value={question.conditions?.slideNo || "1"}
                              onChange={(e) =>
                                handleConditionChange(
                                  idx,
                                  "slideNo",
                                  e.target.value,
                                )
                              }
                            >
                              {Array.from({ length: idx }, (_, i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                            {showErrors && errors[`${baseKey}condition`] && (
                              <div className="error">
                                {errors[`${baseKey}condition`]}
                              </div>
                            )}
                          </div>

                          <div className="condition-value">
                            <label>Condition</label>
                            <select
                              value={question.conditions?.condition || "equals"}
                              onChange={(e) =>
                                handleConditionChange(
                                  idx,
                                  "condition",
                                  e.target.value,
                                )
                              }
                            >
                              <option value="equals">Equals</option>
                              <option value="notequals">Not equals</option>
                              <option value="contains">Contains</option>
                              <option value="notcontains">Not contains</option>
                            </select>
                          </div>

                          <div className="value">
                            <label>Value</label>
                            <input
                              type="text"
                              value={question.conditions?.value || ""}
                              onChange={(e) =>
                                handleConditionChange(
                                  idx,
                                  "value",
                                  e.target.value,
                                )
                              }
                              placeholder="Value"
                              maxLength={100}
                            />
                            {showErrors && errors[`${baseKey}value`] && (
                              <div className="error">
                                {errors[`${baseKey}value`]}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          );
        })}

        {/* Add new slide */}
        {field.questions.length < maxSteps && (
          <button
            type="button"
            onClick={addQuestion}
            className="add-new-card-button"
          >
            Add new slide
          </button>
        )}

        {/* Actions */}
        <div className="actions">
          {isPreviewButtonEnabled() && (
            <button
              type="button"
              onClick={openPreviewModal}
              className="secondary-button"
            >
              Preview
            </button>
          )}
          <button
            type="button"
            onClick={saveField}
            className={`primary-button ${isSaving ? "loading" : ""}`}
            disabled={isSaving}
          >
            Save
          </button>
        </div>
      </div>

      {/* The modal */}
      <PreviewModal
        isOpen={isModalOpen}
        onClose={() => navigate(location.pathname.replace("/preview", ""))}
        field={field}
        companyId={dashboardData.user.companyId}
      />
    </div>
  );
};

export default FieldForm;
