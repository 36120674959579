// src/pages/dashboard/insights/Fields.js

import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics"; // Import logEvent
import { analytics } from "../../../firebase"; // Import analytics instance

const Fields = () => {
  const { dashboardData } = useDashboard();
  const [fields, setFields] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const navigate = useNavigate();

  const { addNotification } = useNotification();

  // Extract the plan limitation for fields
  const planLimit = dashboardData?.activePlan?.limitations?.fields;

  useEffect(() => {
    if (dashboardData.company.fields) {
      const fieldsArray = Array.isArray(dashboardData.company.fields)
        ? dashboardData.company.fields
        : Object.values(dashboardData.company.fields);
      setFields(fieldsArray);
    }
  }, [dashboardData.company.fields]);

  // Log page view when component mounts
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: "Fields",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Runs once on mount

  // Log limit reached banner display
  useEffect(() => {
    if (planLimit !== undefined && fields.length >= planLimit) {
      if (analytics) {
        logEvent(analytics, "limit_reached_banner_show", {
          limit: planLimit,
        });
      }
    }
  }, [planLimit, fields.length]);

  const addField = () => {
    navigate("/dashboard/fields/add");

    // Log Add New Click event
    if (analytics) {
      logEvent(analytics, "add_new_click", {
        action: "Add New Field",
      });
    }
  };

  const confirmDeleteField = (index) => {
    setFieldToDelete(index);
    setShowDeleteModal(true);
  };

  const deleteField = async () => {
    try {
      const fieldToRemove = fields[fieldToDelete];
      const newFields = [...fields];
      newFields.splice(fieldToDelete, 1);

      const fieldDocRef = doc(
        db,
        "companies",
        dashboardData.user.companyId,
        "fields",
        fieldToRemove.id,
      );

      await deleteDoc(fieldDocRef);

      setFields(newFields);
      setShowDeleteModal(false);

      addNotification({
        title: "Success!",
        message: "Field deleted successfully.",
        icon: "check_circle",
        type: "success",
      });

      // Log Delete Click event
      if (analytics) {
        logEvent(analytics, "delete_click", {
          field_id: fieldToRemove.id,
          field_name: fieldToRemove.name,
        });
      }
    } catch (error) {
      console.error("Error deleting field:", error);
      addNotification({
        title: "Error",
        message: `Error deleting field: ${error.message}`,
        icon: "error",
        type: "error",
      });
    }
  };

  const handleEditField = (fieldId) => {
    navigate(`/dashboard/fields/edit/${fieldId}`);

    // Log Edit Click event
    if (analytics) {
      logEvent(analytics, "edit_click", {
        field_id: fieldId,
        action: "Edit Field",
      });
    }
  };

  // Determine if the user has reached the field limit
  const isLimitReached = planLimit !== undefined && fields.length >= planLimit;

  return (
    <>
      <div className="actions">
        {fields.length === 0 ? (
          <div className="no-data-wrapper">
            <span className="icon">settings</span>
            <h2>No fields here yet!</h2>
            <p>Let's get started—add your first field to begin.</p>
            {!isLimitReached ? (
              <button className="primary-button" onClick={addField}>
                Add First Field
              </button>
            ) : (
              <div className="upgrade-message">
                <span className="icon">warning</span>
                <p>
                  You have reached the maximum number of fields allowed for your
                  current plan.
                </p>
                <button
                  className="secondary-button"
                  onClick={() => navigate("/dashboard/upgrade")}
                >
                  Upgrade Plan
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="center-layout">
            <div className="description">
              <div className="title-section">
                <h2>Your Fields</h2>
                <p>
                  Define the fields where you want FillyForm to help your users
                  complete forms with magic fill.
                </p>
              </div>
            </div>
            <ul className="fields-list">
              {fields.map((field, index) => (
                <li key={field.id} className="field-item">
                  <div className="field-info">
                    <h3>{field.name}</h3>
                    <span>{field.charLimit} char limit</span>
                  </div>
                  <div className="field-actions">
                    <span
                      className="icon"
                      onClick={() => handleEditField(field.id)}
                      title="Edit Field"
                    >
                      edit
                    </span>
                    <span
                      className="icon"
                      onClick={() => confirmDeleteField(index)}
                      title="Delete Field"
                    >
                      delete
                    </span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="actions-footer">
              {!isLimitReached ? (
                <button className="primary-button" onClick={addField}>
                  Add New
                </button>
              ) : (
                <div className="upgrade-message">
                  <span className="icon">warning</span>
                  <p>
                    You have reached the maximum number of fields allowed for
                    your current plan.
                  </p>
                  <button
                    className="secondary-button"
                    onClick={() => navigate("/dashboard/store")}
                  >
                    Upgrade Plan
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close icon"
              onClick={() => setShowDeleteModal(false)}
              title="Close Modal"
            >
              close
            </span>
            <div className="icon modal-icon">delete</div>
            <h2>Are you sure you want to delete this field?</h2>
            <div className="modal-actions">
              <button
                className="primary-button"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button className="secondary-button" onClick={deleteField}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Fields;
