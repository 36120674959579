import React, { useState, useEffect } from "react";
import { useDashboard } from "../context/DashboardContext";
import { useAuth } from "../context/AuthContext";
import { useNotification } from "../context/NotificationContext";
import Sidebar from "../components/Sidebar";
import { Routes, Route, useNavigate } from "react-router-dom";

import AccountSettings from "./dashboard/profile/AccountSettings";
import WidgetSettings from "./dashboard/widget/WidgetSettings";
import Store from "./dashboard/store/Store";
import Subscriptions from "./dashboard/subscriptions/Subscriptions";
import Insights from "./dashboard/insights/Insights";
import Install from "./dashboard/install/Install";

import logo from "../assets/images/fillyform-logo.svg";
import { sendEmailVerification } from "firebase/auth";

/**
 * Returns true if a Firestore timestamp indicates a past date/time (i.e., it's "expired").
 */
const isExpired = (timestamp) => {
  if (!timestamp?.seconds) return false;
  return new Date(timestamp.seconds * 1000).getTime() < Date.now();
};

const Dashboard = () => {
  const { dashboardData } = useDashboard();
  const { currentUser } = useAuth();
  const { addNotification } = useNotification();

  const [isSending, setIsSending] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Track whether the user’s subscription is expired, and whether monthly limit is reached
  const [licenseExpired, setLicenseExpired] = useState(false);
  const [monthlyLimitReached, setMonthlyLimitReached] = useState(false);

  const navigate = useNavigate();

  // Re-send Email Verification
  const handleResendEmail = async () => {
    setIsSending(true);
    try {
      await sendEmailVerification(currentUser);
      addNotification({
        icon: "check_circle",
        title: "Success",
        message: "Validation email sent. Please check your inbox.",
        type: "success",
      });
    } catch (error) {
      addNotification({
        icon: "error",
        title: "Failed to resend validation email:",
        message: `${error}`,
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (!dashboardData) return;

    // Hardcode the monthly "max" as 20
    const fillCount = dashboardData?.company?.fillCounter || 0;

    // Check subscription status
    const subs = dashboardData.subscriptions || [];
    const allSubsAreExpired =
      subs.length > 0 && subs.every((sub) => isExpired(sub.current_period_end));

    // If user has at least one sub, but all are expired => "license expired"
    setLicenseExpired(subs.length > 0 && allSubsAreExpired);

    // If user has reached or exceeded 20 fills => monthly limit is reached
    setMonthlyLimitReached(fillCount >= 20);
  }, [dashboardData]);

  // Show the banner if at least one of these conditions is true
  const showLicenseBanner = licenseExpired || monthlyLimitReached;

  // Determine the text displayed in the banner
  let bannerMessage = "";
  let bannerCta = "";
  if (licenseExpired && monthlyLimitReached) {
    bannerMessage =
      "Your subscription has expired, and you've also reached your monthly limit.";
    bannerCta = "Upgrade or Renew";
  } else if (licenseExpired) {
    bannerMessage = "Your subscription has expired.";
    bannerCta = "Renew";
  } else if (monthlyLimitReached) {
    bannerMessage = "You have reached your monthly limit.";
    bannerCta = "Upgrade";
  }

  return (
    <div className="page-content">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="mobile-sidebar">
        <img src={logo} className="logo" alt="Filly Form" />
        <span className="icon" onClick={() => setSidebarOpen(!sidebarOpen)}>
          menu
        </span>
      </div>

      <div className="view-wrapper">
        {/* Banner if email not verified */}
        {!currentUser?.emailVerified && (
          <div className="banner">
            <div className="text">
              <div className="icon">error</div>
              <p>
                Your account is not validated. Please check your email to
                validate your account.
              </p>
            </div>
            <div>
              <button
                onClick={handleResendEmail}
                className={`primary-button ${isSending ? "loading" : ""}`}
                disabled={isSending}
              >
                Resend Validation Email
              </button>
            </div>
          </div>
        )}

        {/* Banner if license expired or monthly limit reached */}
        {showLicenseBanner && (
          <div className="banner">
            <div className="text">
              <div className="icon">warning</div>
              <p>
                {bannerMessage}
                <br />
                {/* Hardcode 20 as the monthly limit, exactly as you did in Subscriptions */}
                You have{" "}
                <strong>
                  {20 - (dashboardData?.company?.fillCounter || 0)}/20
                </strong>{" "}
                fills left this month.
              </p>
            </div>
            <div>
              <button
                className="primary-button"
                onClick={() => navigate("/dashboard/store")}
              >
                {bannerCta}
              </button>
            </div>
          </div>
        )}

        <div className="view">
          <Routes>
            <Route path="account-settings/*" element={<AccountSettings />} />
            <Route path="fields/*" element={<WidgetSettings />} />
            <Route path="store/*" element={<Store />} />
            <Route path="subscriptions/*" element={<Subscriptions />} />
            <Route path="insights/*" element={<Insights />} />
            <Route path="install/*" element={<Install />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
