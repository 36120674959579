// src/pages/dashboard/insights/InsightsData.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  collection,
  query,
  where,
  Timestamp,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { db, analytics } from "../../../firebase"; // Adjust the path as needed
import { logEvent } from "firebase/analytics"; // Import logEvent

const InsightsData = ({
  dashboardData,
  selectedFieldId,
  setSelectedFieldId,
  chartType,
  setChartType,
  dateRange,
  setDateRange,
  granularity,
  setGranularity,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleFieldChange,
}) => {
  const [insightsData, setInsightsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // For error handling

  const publishedFields =
    dashboardData?.company?.fields?.filter(
      (field) => field.published === "true",
    ) || [];

  const subscribeToEvents = useCallback(() => {
    if (dashboardData) {
      setLoading(true); // Start loading
      setError(null); // Reset error state
      const companyId = dashboardData.user.companyId;
      const fieldsRef = collection(
        db,
        "insights",
        companyId,
        "fields",
        selectedFieldId,
        "events",
      );

      const startTimestamp = Timestamp.fromDate(
        new Date(startDate + "T00:00:00"),
      );
      const endTimestamp = Timestamp.fromDate(new Date(endDate + "T23:59:59"));

      const q = query(
        fieldsRef,
        where("createdAt", ">=", startTimestamp),
        where("createdAt", "<=", endTimestamp),
        orderBy("createdAt"),
        limit(1000),
      );

      return onSnapshot(
        q,
        (snapshot) => {
          const insights = [];
          snapshot.forEach((doc) =>
            insights.push({ id: doc.id, ...doc.data() }),
          );

          setInsightsData(insights);
          setLoading(false); // Stop loading

          // Log data fetch success
          if (analytics) {
            logEvent(analytics, "data_fetch_success", {
              field_id: selectedFieldId,
              number_of_events: insights.length,
            });
          }
        },
        (error) => {
          console.error("Error fetching insights:", error);
          setError("Failed to load insights data. Please try again later.");
          setLoading(false); // Stop loading in case of error

          // Log data fetch failure
          if (analytics) {
            logEvent(analytics, "data_fetch_failure", {
              field_id: selectedFieldId,
              error: error.message,
            });
          }
        },
      );
    }
  }, [dashboardData, selectedFieldId, startDate, endDate]);

  useEffect(() => {
    let unsubscribeEvents;

    if (selectedFieldId && startDate && endDate) {
      unsubscribeEvents = subscribeToEvents();
    }

    return () => {
      if (unsubscribeEvents) unsubscribeEvents();
    };
  }, [selectedFieldId, startDate, endDate, subscribeToEvents]);

  // Log page view when component mounts
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: "Insights Data",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount

  const handleFieldChangeInternal = (e) => {
    const newFieldId = e.target.value;
    setSelectedFieldId(newFieldId);
    handleFieldChange(e); // Propagate to parent

    // Log field selection
    if (analytics) {
      logEvent(analytics, "field_selection", {
        field_id: newFieldId,
      });
    }
  };

  const handleDateRangeChange = (e) => {
    const selectedRange = e.target.value;
    let newStartDate, newEndDate;

    const today = new Date();
    switch (selectedRange) {
      case "Today":
        newStartDate = newEndDate = today;
        break;
      case "Yesterday":
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        newStartDate = newEndDate = yesterday;
        break;
      case "Last 7 days":
        const last7Days = new Date();
        last7Days.setDate(today.getDate() - 7);
        newStartDate = last7Days;
        newEndDate = today;
        break;
      case "Last 30 days":
        const last30Days = new Date();
        last30Days.setDate(today.getDate() - 30);
        newStartDate = last30Days;
        newEndDate = today;
        break;
      case "This month":
        newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
        newEndDate = today;
        break;
      case "Last month":
        newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "This year":
        newStartDate = new Date(today.getFullYear(), 0, 1);
        newEndDate = today;
        setGranularity("month");
        break;
      case "Last year":
        newStartDate = new Date(today.getFullYear() - 1, 0, 1);
        newEndDate = new Date(today.getFullYear() - 1, 11, 31);
        setGranularity("month");
        break;
      case "Custom range":
        newStartDate = new Date(); // Default to today minus 7 days
        newStartDate.setDate(newStartDate.getDate() - 7);
        newEndDate = new Date();
        break;
      default:
        const defaultStart = new Date();
        defaultStart.setDate(defaultStart.getDate() - 7);
        newStartDate = defaultStart;
        newEndDate = new Date();
    }

    setDateRange(selectedRange);
    setStartDate(newStartDate.toISOString().split("T")[0]);
    setEndDate(newEndDate.toISOString().split("T")[0]);

    // Log date range change
    if (analytics) {
      logEvent(analytics, "date_range_change", {
        selected_range: selectedRange,
        start_date: newStartDate.toISOString().split("T")[0],
        end_date: newEndDate.toISOString().split("T")[0],
      });
    }
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    // Log custom start date change
    if (analytics) {
      logEvent(analytics, "start_date_change", {
        start_date: newStartDate,
      });
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);

    // Log custom end date change
    if (analytics) {
      logEvent(analytics, "end_date_change", {
        end_date: newEndDate,
      });
    }
  };

  const handleGranularityChange = (e) => {
    const newGranularity = e.target.value;
    setGranularity(newGranularity);

    // Log granularity change
    if (analytics) {
      logEvent(analytics, "granularity_change", {
        granularity: newGranularity,
      });
    }
  };

  const handleChartTypeChange = (e) => {
    const newChartType = e.target.value;
    setChartType(newChartType);

    // Log chart type change
    if (analytics) {
      logEvent(analytics, "chart_type_change", {
        chart_type: newChartType,
      });
    }
  };

  // Define event types and updated colors
  const eventTypes = ["Display", "Click", "Fill"];
  const eventColors = {
    Display: "#ecc25a",
    Click: "#e8ad37",
    Fill: "#c66d19",
  };

  // Process data for Bar and Line Charts
  const processData = useCallback(
    (data) => {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      let dateMap = {};

      if (!data || data.length === 0) {
        return [];
      }

      const rangeExceedsTwoYears =
        endDateObj.getFullYear() - startDateObj.getFullYear() > 2;

      if (rangeExceedsTwoYears || granularity === "year") {
        dateMap = eventTypes.reduce((acc, type) => {
          acc[type] = data.reduce((typeAcc, item) => {
            const date = new Date(item.createdAt.seconds * 1000);
            const year = date.getFullYear();
            if (!typeAcc[year]) {
              typeAcc[year] = 0;
            }
            typeAcc[year] += item.event === type.toLowerCase() ? 1 : 0;
            return typeAcc;
          }, {});
          return acc;
        }, {});
      } else if (granularity === "month") {
        dateMap = eventTypes.reduce((acc, type) => {
          acc[type] = data.reduce((typeAcc, item) => {
            const date = new Date(item.createdAt.seconds * 1000);
            const monthYear = `${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
            if (!typeAcc[monthYear]) {
              typeAcc[monthYear] = 0;
            }
            typeAcc[monthYear] += item.event === type.toLowerCase() ? 1 : 0;
            return typeAcc;
          }, {});
          return acc;
        }, {});
      } else if (granularity === "week") {
        dateMap = eventTypes.reduce((acc, type) => {
          acc[type] = data.reduce((typeAcc, item) => {
            const date = new Date(item.createdAt.seconds * 1000);
            const startOfWeek = new Date(
              date.setDate(date.getDate() - date.getDay()),
            );
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(endOfWeek.getDate() + 6);
            const weekYear = `${startOfWeek.getDate()} ${startOfWeek.toLocaleString(
              "default",
              { month: "short" },
            )} ${startOfWeek.getFullYear()} - ${endOfWeek.getDate()} ${endOfWeek.toLocaleString(
              "default",
              { month: "short" },
            )} ${endOfWeek.getFullYear()}`;
            if (!typeAcc[weekYear]) {
              typeAcc[weekYear] = 0;
            }
            typeAcc[weekYear] += item.event === type.toLowerCase() ? 1 : 0;
            return typeAcc;
          }, {});
          return acc;
        }, {});
      } else {
        dateMap = eventTypes.reduce((acc, type) => {
          acc[type] = data.reduce((typeAcc, item) => {
            const date = new Date(
              item.createdAt.seconds * 1000,
            ).toLocaleDateString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            });
            if (!typeAcc[date]) {
              typeAcc[date] = 0;
            }
            typeAcc[date] += item.event === type.toLowerCase() ? 1 : 0;
            return typeAcc;
          }, {});
          return acc;
        }, {});
      }

      const labels = Object.keys(dateMap[eventTypes[0]] || {});
      const processedData = labels.map((label) => {
        const entry = { name: label };
        eventTypes.forEach((type) => {
          entry[type] = dateMap[type][label] || 0;
        });
        return entry;
      });

      return processedData;
    },
    [granularity, startDate, endDate, eventTypes],
  );

  const processedChartData = useMemo(
    () => processData(insightsData),
    [insightsData, processData],
  );

  // Aggregate data for PieChart
  const pieData = useMemo(() => {
    const totals = eventTypes.map((type) => {
      const lowerType = type.toLowerCase();
      const count = insightsData.reduce(
        (acc, item) => (item.event === lowerType ? acc + 1 : acc),
        0,
      );
      return { name: type, value: count };
    });
    return totals;
  }, [insightsData, eventTypes]);

  // Custom label rendering for PieChart
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#171719"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={10} // Changed from 12px to 10px
      >
        {`${name}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // Render the appropriate chart based on chartType
  const renderChart = () => {
    // Determine if there's data to display
    const hasBarLineData = processedChartData && processedChartData.length > 0;
    const hasPieData =
      pieData && pieData.reduce((acc, item) => acc + item.value, 0) > 0;

    if (
      (chartType === "Pie" && !hasPieData) ||
      (["Bar", "Line"].includes(chartType) && !hasBarLineData)
    ) {
      return (
        <div className="no-records">No records for the selected dates.</div>
      );
    }

    if (chartType === "Bar") {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={processedChartData}
            margin={{ top: 20, right: 40, left: 0, bottom: 20 }}
          >
            <CartesianGrid
              stroke="rgba(113, 112, 117, 0.4)"
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 10, fill: "#171719" }} // Changed fontSize to 10
              stroke="#c6c6c8"
              axisLine={{ stroke: "#c6c6c8" }}
              tickLine={false}
              dy={10}
            />
            <YAxis
              allowDecimals={false}
              tick={{ fontSize: 10, fill: "#171719" }} // Changed fontSize to 10
              stroke="#c6c6c8"
              axisLine={{ stroke: "#c6c6c8" }}
              tickLine={false}
              dx={-10}
            />
            <Tooltip
              contentStyle={{
                fontSize: "12px",
                backgroundColor: "#ffffff",
                color: "#171719",
                borderRadius: "8px",
                textAlign: "left", // Align text to the left
              }}
              itemStyle={{ fontSize: "12px", color: "#171719" }}
              cursor={{
                stroke: "rgba(23, 23, 25, 0.1)",
                strokeWidth: 1,
                strokeDasharray: "3 3",
                fill: "rgba(23, 23, 25, 0.03)",
              }}
            />
            <Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{ fontSize: "12px", color: "#171719" }}
              iconType="circle"
              iconSize={8}
            />
            {eventTypes.map((type) => (
              <Bar
                key={type}
                dataKey={type}
                fill={eventColors[type]}
                stackId="a" // Ensures stacking
                radius={[0, 0, 0, 0]}
                stroke="transparent"
                strokeWidth={1}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (chartType === "Line") {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={processedChartData}
            margin={{ top: 20, right: 40, left: 0, bottom: 20 }}
          >
            <CartesianGrid
              stroke="rgba(113, 112, 117, 0.4)"
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 10, fill: "#171719" }} // Changed fontSize to 10
              stroke="#c6c6c8"
              axisLine={{ stroke: "#c6c6c8" }}
              tickLine={false}
              dy={10}
            />
            <YAxis
              allowDecimals={false}
              tick={{ fontSize: 10, fill: "#171719" }} // Changed fontSize to 10
              stroke="#c6c6c8"
              axisLine={{ stroke: "#c6c6c8" }}
              tickLine={false}
              dx={-10}
            />
            <Tooltip
              contentStyle={{
                fontSize: "12px",
                backgroundColor: "#ffffff",
                color: "#171719",
                borderRadius: "8px",
                textAlign: "left", // Align text to the left
              }}
              itemStyle={{ fontSize: "12px", color: "#171719" }}
              cursor={{
                stroke: "rgba(23, 23, 25, 0.1)",
                strokeWidth: 1,
                strokeDasharray: "3 3",
                fill: "rgba(23, 23, 25, 0.03)",
              }}
            />
            <Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{
                fontSize: "12px",
                color: "#171719",
              }}
              iconType="circle"
              iconSize={8}
            />
            {eventTypes.map((type) => (
              <Line
                key={type}
                type="monotone"
                dataKey={type}
                stroke={eventColors[type]}
                strokeWidth={2}
                dot={{
                  r: 4,
                  stroke: eventColors[type],
                  strokeWidth: 2,
                  fill: "#fff",
                }}
                activeDot={{
                  r: 6,
                  fill: "rgba(113, 112, 117, 0.1)", // Hover effect with 10% opacity
                }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      );
    } else if (chartType === "Pie") {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }} // Adjusted margins
          >
            <Tooltip
              contentStyle={{
                fontSize: "12px",
                backgroundColor: "#ffffff",
                color: "#171719",
                borderRadius: "8px",
                textAlign: "left", // Align text to the left
              }}
              itemStyle={{ fontSize: "12px", color: "#171719" }}
            />
            <Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{ fontSize: "12px", color: "#171719" }}
              iconType="circle"
              iconSize={8}
            />
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              label={renderCustomizedLabel} // Custom label renderer with 10px font size
              labelLine={false} // Hide label lines if desired
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={eventColors[entry.name]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="events">
      <div className="field-and-type-row">
        <div className="form-field">
          <label htmlFor="field-select">Select Field ID</label>
          <select
            id="field-select"
            value={selectedFieldId}
            onChange={handleFieldChangeInternal}
          >
            {publishedFields.map((field) => (
              <option key={field.id} value={field.id}>
                {field.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="range-wrapper">
        <div className="range">
          <div className="form-field">
            <label htmlFor="date-range-select">Date Range</label>
            <select
              id="date-range-select"
              value={dateRange}
              onChange={handleDateRangeChange}
            >
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="Last 7 days">Last 7 days</option>
              <option value="Last 30 days">Last 30 days</option>
              <option value="This month">This month</option>
              <option value="Last month">Last month</option>
              <option value="This year">This year</option>
              <option value="Last year">Last year</option>
              <option value="Custom range">Custom range</option>
            </select>
          </div>
          {dateRange === "Custom range" && (
            <>
              <div className="form-field">
                <label htmlFor="start-date">Start Date</label>
                <input
                  id="start-date"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="form-field">
                <label htmlFor="end-date">End Date</label>
                <input
                  id="end-date"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </>
          )}
          <div className="form-field">
            <label htmlFor="granularity-select">Granularity</label>
            <select
              id="granularity-select"
              value={granularity}
              onChange={handleGranularityChange}
            >
              <option value="day">Per Day</option>
              <option value="week">Per Week</option>
              <option value="month">Per Month</option>
              <option value="year">Per Year</option>
            </select>
          </div>
        </div>

        <div className="form-field">
          <label htmlFor="chart-type-select">Select Chart Type</label>
          <select
            id="chart-type-select"
            value={chartType}
            onChange={handleChartTypeChange}
          >
            <option value="Bar">Bar</option>
            <option value="Line">Line</option>
            <option value="Pie">Pie</option>
            {/* Removed Funnel option */}
          </select>
        </div>
      </div>

      <div
        className="chart-container"
        style={{ position: "relative", height: "600px" }}
      >
        {loading ? (
          <div className="section-loader" role="status" aria-live="polite">
            <div className="loader"></div>
            <p>Preparing your data ...</p>
          </div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          renderChart()
        )}
      </div>
    </div>
  );
};

export default InsightsData;
