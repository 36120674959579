import React, { useState, useEffect } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

const Subscriptions = () => {
  const { dashboardData, generateStripeLink } = useDashboard();
  const [manageLoading, setManageLoading] = useState(false);
  const [invoicesLoading, setInvoicesLoading] = useState(false);

  const planLimitations = dashboardData?.activePlan?.limitations || {};
  const navigate = useNavigate();

  // Log Page View on Component Mount
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_title: "Subscriptions",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const handleManageSubscription = async () => {
    setManageLoading(true);
    try {
      const url = await generateStripeLink();
      window.open(url, "_blank");

      // Log Manage Subscription Click
      logEvent(analytics, "manage_subscription_click", {
        action: "manage_subscription",
        timestamp: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error opening Stripe portal:", error);
    }
    setManageLoading(false);
  };

  const handleViewInvoices = async () => {
    setInvoicesLoading(true);
    try {
      const url = await generateStripeLink();
      window.open(url, "_blank");

      // Log View Invoices Click
      logEvent(analytics, "view_invoices_click", {
        action: "view_invoices",
        timestamp: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error opening Stripe portal:", error);
    }
    setInvoicesLoading(false);
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "eur":
        return "€";
      case "usd":
        return "$";
      default:
        return currency?.toUpperCase() || "";
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp?.seconds) return "";
    return new Date(timestamp.seconds * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  // A small helper to check if a subscription is expired
  const isExpired = (timestamp) => {
    if (!timestamp?.seconds) return false;
    return new Date(timestamp.seconds * 1000).getTime() < Date.now();
  };

  const calculateDaysLeft = (endTimestamp) => {
    if (!endTimestamp?.seconds) return 0;
    const endTime = new Date(endTimestamp.seconds * 1000).getTime();
    const currentTime = Date.now();
    return Math.ceil((endTime - currentTime) / (1000 * 60 * 60 * 24));
  };

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const { subscriptions, plans, company } = dashboardData;

  const getPlanName = (priceId) => {
    const plan = plans.find((plan) =>
      plan?.productData?.prices.some((price) => price.id === priceId),
    );
    return plan ? plan.name : "Unknown Plan";
  };

  const userOnFreePlan = subscriptions.length === 0;

  return (
    <div className="center-layout">
      {/* Title/intro section */}
      <div className="title-section">
        <h2>Manage Your Subscriptions</h2>
        <p>View your plan details and track your usage.</p>
      </div>

      {/* If user has no subscriptions, show the free plan details */}
      {userOnFreePlan ? (
        <div className="subscription-card">
          <h3>
            <span className="plan-icon plan-icon-free"></span>
            <span>Free</span>
          </h3>
          <p className="status">Active</p>
          <div className="fills">
            Monthly Fills Remaining:{" "}
            {planLimitations?.fills - (company?.fillCounter || 0)}/
            {planLimitations?.fills}
          </div>
          <button
            className="primary-button"
            onClick={() => navigate("/dashboard/store")}
          >
            Explore Plans
          </button>
        </div>
      ) : (
        /* Otherwise, list each subscription */
        subscriptions.map((subscription) => {
          const priceId = subscription.items[0]?.price?.id;
          const planName = getPlanName(priceId);

          return (
            <div key={subscription.id} className="subscription-card">
              <h3>
                <span
                  className={`plan-icon plan-icon-${planName.toLowerCase()}`}
                ></span>{" "}
                <span>{planName}</span>
              </h3>

              {subscription.status === "trialing" ? (
                <>
                  <p className="subscription-date">
                    Trial ends on: {formatDate(subscription.trial_end)}
                  </p>
                  <p className="status trial">
                    Trial – {calculateDaysLeft(subscription.trial_end)} days
                    left
                  </p>
                </>
              ) : subscription.cancel_at_period_end ? (
                <>
                  <p className="subscription-date">
                    {isExpired(subscription.current_period_end)
                      ? "Expired"
                      : "Expires"}{" "}
                    on: {formatDate(subscription.current_period_end)}
                  </p>
                  <p className="status cancelled">
                    {isExpired(subscription.current_period_end)
                      ? "Expired"
                      : "Canceled"}
                  </p>
                </>
              ) : (
                <>
                  <p className="subscription-date">
                    {isExpired(subscription.current_period_end)
                      ? "Expired"
                      : "Renews"}{" "}
                    on {formatDate(subscription.current_period_end)} for{" "}
                    {subscription.items && subscription.items.length > 0
                      ? `${getCurrencySymbol(
                          subscription.items[0].price.currency,
                        )} ${subscription.items[0].price.unit_amount / 100}`
                      : "N/A"}
                  </p>
                  <p className="status">
                    {isExpired(subscription.current_period_end)
                      ? "Expired"
                      : "Active"}
                  </p>
                </>
              )}

              <div className="actions">
                <span
                  className={`secondary-button ${
                    invoicesLoading ? "loading" : ""
                  }`}
                  onClick={handleViewInvoices}
                >
                  View Invoices
                </span>

                {subscription.cancel_at_period_end ? (
                  <span
                    className={`primary-button ${
                      manageLoading ? "loading" : ""
                    }`}
                    onClick={handleManageSubscription}
                    disabled={manageLoading}
                  >
                    Renew now
                  </span>
                ) : (
                  !isExpired(subscription.current_period_end) && (
                    <span
                      className={`accent-link ${
                        manageLoading ? "loading" : ""
                      }`}
                      onClick={handleManageSubscription}
                      disabled={manageLoading}
                    >
                      Manage Subscription{" "}
                      <span className="icon">open_in_new</span>
                    </span>
                  )
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Subscriptions;
