import React, { useEffect, useContext, useState, useRef } from "react";
import { useAuth } from "../../../context/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import { useDashboard } from "../../../context/DashboardContext";
import { useNotification } from "../../../context/NotificationContext";
import { logEvent } from "firebase/analytics"; // Import logEvent
import { analytics } from "../../../firebase"; // Import analytics instance

const Install = () => {
  const { currentUser } = useAuth();
  const [sendEmailVerificationIsLoading, setSendEmailVerificationIsLoading] =
    useState(false);
  const [copyButtonIsLoading, setCopyButtonIsLoading] = useState(false);
  const textAreaRef = useRef(null);
  const { dashboardData } = useDashboard();
  const { addNotification } = useNotification();

  const iframeSrc = `<script src="https://widget.fillyform.com/script.js?cid=${
    dashboardData.user.companyId ? dashboardData.user.companyId : ""
  }"></script>`;

  // Log Page View on Component Mount
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_title: "Installation Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const resendVerificationEmail = () => {
    setSendEmailVerificationIsLoading(true);
    sendEmailVerification(currentUser)
      .then(() => {
        setSendEmailVerificationIsLoading(false);
        addNotification({
          title: "Success!",
          message: "Verification email sent successfully",
          icon: "check_circle",
          type: "success",
        });

        // Log Resend Verification Email Event
        logEvent(analytics, "resend_verification_email", {
          user_id: currentUser.uid,
          timestamp: new Date().toISOString(),
        });
      })
      .catch(() => {
        setSendEmailVerificationIsLoading(false);
        addNotification({
          title: "Error!",
          message: "Something went wrong, please try again.",
          icon: "warning",
          type: "error",
        });

        // Log Resend Verification Email Error
        logEvent(analytics, "resend_verification_email_error", {
          user_id: currentUser.uid,
          timestamp: new Date().toISOString(),
        });
      });
  };

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopyButtonIsLoading(true);
    addNotification({
      title: "Success!",
      message: "Script copied to clipboard",
      icon: "check_circle",
      type: "success",
    });
    setTimeout(() => {
      setCopyButtonIsLoading(false);
    }, 1000);

    // Log Copy to Clipboard Event
    logEvent(analytics, "copy_to_clipboard", {
      user_id: currentUser?.uid || "anonymous",
      timestamp: new Date().toISOString(),
    });
  };

  if (currentUser && !currentUser.emailVerified) {
    return (
      <div className="mt-80 mb-80">
        <div className="large-container">
          <div className="installation-instructions">
            <h1>Email Verification Required</h1>
            <p>To publish your widget, please verify your email address.</p>
            <button
              className={`primary-button blue-button medium-button mt-40 ${
                sendEmailVerificationIsLoading ? "loading disabled" : ""
              }`}
              onClick={resendVerificationEmail}
              disabled={sendEmailVerificationIsLoading}
            >
              {sendEmailVerificationIsLoading
                ? "Sending..."
                : "Resend Verification Email"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="installation-steps">
      <h2>Get Your Widget Live in Just a Few Steps</h2>
      <ol>
        <li>
          Configure Your Fields – Add the necessary fields and customize them to
          match your form’s needs.
        </li>
        <li>
          Publish Your Changes – Ensure your form is active and ready for use.
        </li>
        <li>
          Copy the Installation Code – Click the button to copy the following
          script:
          <code>
            {iframeSrc}
            {document.queryCommandSupported("copy") && (
              <span
                className={`icon copy-button secondary-button ${
                  copyButtonIsLoading ? "disabled" : ""
                }`}
                onClick={copyToClipboard}
              >
                {copyButtonIsLoading ? "done" : "content_copy"}
              </span>
            )}
          </code>
        </li>
        <li>
          {" "}
          Paste the script right before the closing &lt;/body&gt; tag on your
          site’s HTML.
        </li>
        <li>
          You're All Set! – Your widget is now live and ready to collect
          responses.
        </li>
      </ol>
      <form>
        <textarea ref={textAreaRef} value={iframeSrc} readOnly />
      </form>
    </div>
  );
};

export default Install;
