// src/components/Auth/Register.jsx

import React, { useState, useEffect } from "react";
import { auth, analytics } from "../../firebase"; // Import analytics
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import { logEvent } from "firebase/analytics";
import logo from "../../assets/images/fillyform-logo.svg";
import googleLogo from "../../assets/images/google-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useNotification } from "../../context/NotificationContext";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAgreed: false,
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);

  const { addNotification } = useNotification();
  const navigate = useNavigate();

  /**
   * Updated password regex:
   * - At least one lowercase
   * - At least one uppercase
   * - At least one digit
   * - At least one non-alphanumeric character
   * - Total length between 8 and 128
   */
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,128}$/;

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      formIsValid = false;
      newErrors["name"] = "Name is required.";
    } else if (formData.name.length > 100) {
      formIsValid = false;
      newErrors["name"] = "Name cannot exceed 100 characters.";
    }

    // Email validation
    const strongEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    if (!formData.email) {
      formIsValid = false;
      newErrors["email"] = "Email is required.";
    } else if (!strongEmailRegex.test(formData.email)) {
      formIsValid = false;
      newErrors["email"] = "Please enter a valid email address.";
    } else if (formData.email.length > 254) {
      formIsValid = false;
      newErrors["email"] = "Email cannot exceed 254 characters.";
    }

    // Password validation
    if (!formData.password) {
      formIsValid = false;
      newErrors["password"] = "Password is required.";
    } else if (!passwordRegex.test(formData.password)) {
      formIsValid = false;
      newErrors["password"] =
        "Password must be 8-128 characters, including 1 uppercase, 1 lowercase, 1 digit, and 1 special character.";
    }

    // Confirm Password validation
    if (!formData.confirmPassword) {
      formIsValid = false;
      newErrors["confirmPassword"] = "Confirming password is required.";
    } else if (formData.password !== formData.confirmPassword) {
      formIsValid = false;
      newErrors["confirmPassword"] = "Passwords do not match.";
    }

    // Terms and Policy agreement
    if (!formData.termsAgreed) {
      formIsValid = false;
      newErrors["termsAgreed"] = "You must agree to the terms and policy.";
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const validateField = (name, value) => {
    const strongEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

    let error = "";
    switch (name) {
      case "name":
        if (!value.trim()) {
          error = "Name is required.";
        } else if (value.length > 100) {
          error = "Name cannot exceed 100 characters.";
        }
        break;
      case "email":
        if (!value) {
          error = "Email is required.";
        } else if (!strongEmailRegex.test(value)) {
          error = "Please enter a valid email address.";
        } else if (value.length > 254) {
          error = "Email cannot exceed 254 characters.";
        }
        break;
      case "password":
        if (!value) {
          error = "Password is required.";
        } else if (!passwordRegex.test(value)) {
          error =
            "Password must be 8-128 characters, including 1 uppercase, 1 lowercase, 1 digit, and 1 special character.";
        }
        break;
      case "confirmPassword":
        if (!value) {
          error = "Confirming password is required.";
        } else if (value !== formData.password) {
          error = "Passwords do not match.";
        }
        break;
      case "termsAgreed":
        if (!value) {
          error = "You must agree to the terms and policy.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    // Validate the changed field
    const error = validateField(name, fieldValue);

    // Update formData and errors states
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const getErrorMessage = (code) => {
    switch (code) {
      case "auth/email-already-in-use":
        return "This email is already in use.";
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/operation-not-allowed":
        return "Operation not allowed. Please contact support.";
      case "auth/weak-password":
        return "Password is too weak.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  /**
   * Handles logging of successful registration events.
   * @param {string} method - The registration method used ('email' or 'google').
   * @param {number} duration - The duration of the registration process in milliseconds.
   */
  const handleRegisterSuccess = (method, duration) => {
    const eventName = method === "email" ? "register_email" : "register_google";
    logEvent(analytics, eventName, { duration });

    addNotification({
      title: "Success!",
      message:
        method === "email"
          ? "Registered successfully. Please check your email for verification."
          : "Registered and signed in with Google successfully.",
      icon: "check_circle",
      type: "success",
    });

    navigate("/dashboard/insights/data");
  };

  /**
   * Handles logging of failed registration attempts.
   * @param {string} method - The registration method used ('email' or 'google').
   * @param {string} errorCode - The error code returned by Firebase.
   */
  const handleRegisterFailure = (method, errorCode) => {
    const eventName =
      method === "email" ? "register_failure_email" : "register_failure_google";
    logEvent(analytics, eventName, { error: errorCode });

    setErrors((prevErrors) => ({
      ...prevErrors,
      firebase: getErrorMessage(errorCode),
    }));
    addNotification({
      title: "Error",
      message: getErrorMessage(errorCode),
      icon: "error",
      type: "error",
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsRegisterLoading(true);
    const registerStartTime = Date.now(); // Start timing registration
    try {
      const newUser = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password,
      );
      await updateProfile(newUser.user, {
        displayName: formData.name,
      });

      if (!newUser.user.emailVerified) {
        await sendEmailVerification(newUser.user);
        addNotification({
          title: "Verification Email Sent",
          message:
            "A verification email has been sent to your email address. Please verify to continue.",
          icon: "info",
          type: "info",
        });
      }

      const registerDuration = Date.now() - registerStartTime;
      handleRegisterSuccess("email", registerDuration);
    } catch (error) {
      handleRegisterFailure("email", error.code);
    } finally {
      setIsRegisterLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setIsGoogleSignInLoading(true);
    const registerStartTime = Date.now(); // Start timing registration
    try {
      await signInWithPopup(auth, provider);
      // Assuming new user creation via Google is considered registration
      const registerDuration = Date.now() - registerStartTime;
      handleRegisterSuccess("google", registerDuration);
    } catch (error) {
      handleRegisterFailure("google", error.code);
    } finally {
      setIsGoogleSignInLoading(false);
    }
  };

  // Log page view event manually
  useEffect(() => {
    if (!analytics) return; // Ensure analytics is initialized

    const pagePath = "/register";
    const pageTitle = "Sign Up - FillyForm";

    document.title = pageTitle; // Set the document title

    logEvent(analytics, "page_view", {
      page_location: window.location.href,
      page_path: pagePath,
      page_title: pageTitle,
    });
  }, [analytics]);

  return (
    <div className="auth-container">
      <img src={logo} alt="FillyForm" className="logo" />
      <form onSubmit={handleRegister}>
        <div className="form-header">
          <h1>Sign up</h1>
          <p>
            Already have an account?{" "}
            <Link className="link" to={`/login`}>
              Sign in here
            </Link>
          </p>
        </div>

        <button
          type="button"
          onClick={handleGoogleSignIn}
          className={`sm-button ${isGoogleSignInLoading ? "loading disabled" : ""}`}
          disabled={isGoogleSignInLoading}
        >
          <img src={googleLogo} alt="Sign up with Google" /> Sign up with Google
        </button>
        <div className="auth-spacer">or</div>

        <fieldset>
          <div className="form-field">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
              className={errors.name ? "has-error" : ""}
              maxLength="100"
              required
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
        </fieldset>

        <fieldset>
          <div className="form-field">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address"
              className={errors.email ? "has-error" : ""}
              maxLength="254"
              required
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
        </fieldset>

        <fieldset>
          <div className="form-field">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="********"
              className={errors.password ? "has-error" : ""}
              maxLength="128"
              required
              autoComplete="new-password"
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="icon show-password"
              role="button"
              aria-label={showPassword ? "Hide password" : "Show password"}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setShowPassword(!showPassword);
                }
              }}
            >
              {showPassword ? "visibility" : "visibility_off"}
            </span>
            {errors.password && <div className="error">{errors.password}</div>}
          </div>
        </fieldset>

        <fieldset>
          <div className="form-field">
            <label>Confirm password</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              onPaste={(e) => e.preventDefault()}
              placeholder="********"
              className={errors.confirmPassword ? "has-error" : ""}
              maxLength="128"
              required
              autoComplete="new-password"
            />
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="icon show-password"
              role="button"
              aria-label={
                showConfirmPassword
                  ? "Hide confirm password"
                  : "Show confirm password"
              }
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setShowConfirmPassword(!showConfirmPassword);
                }
              }}
            >
              {showConfirmPassword ? "visibility" : "visibility_off"}
            </span>
            {errors.confirmPassword && (
              <div className="error">{errors.confirmPassword}</div>
            )}
          </div>
        </fieldset>

        <fieldset>
          <div className="form-field-checkbox">
            <label className={errors.termsAgreed ? "has-error" : ""}>
              <input
                type="checkbox"
                name="termsAgreed"
                checked={formData.termsAgreed}
                onChange={handleInputChange}
                className="visually-hidden custom-checkbox"
              />
              <span className="checkbox-indicator"></span>I agree to the{" "}
              <a
                href="https://filly-form.com/terms"
                target="_blank"
                className="link"
                rel="noreferrer"
              >
                Terms & Policy
              </a>
            </label>
            {errors.termsAgreed && (
              <div className="error">{errors.termsAgreed}</div>
            )}
          </div>
        </fieldset>

        <button
          type="submit"
          className={`primary-button ${
            isRegisterLoading ? "loading disabled" : ""
          }`}
          disabled={isRegisterLoading}
        >
          {isRegisterLoading ? "Signing up..." : "Sign up"}
        </button>
        {errors.firebase && <div className="error">{errors.firebase}</div>}
      </form>
      <p className="copyrights">© {new Date().getFullYear()} FillyForm</p>
    </div>
  );
};

export default Register;
